<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p>
        <router-link :to="{ name: 'Users', query: this.createSearchQuery() }" class="ashen-link">ユーザー管理</router-link>
        <i
            class="fas fa-angle-right form-control-color"></i>ユーザー一括新規登録
      </p>
    </div>
    <!--    <div v-if="!addUserScreen && !addNewBulkUserScreen && !batchRegistrationUserConfirmationScreen &&-->
    <!--        !batchRegistrationUserSuccessMsgScreen && !tournamentUserListScreen">-->
    <!--      <div v-if="deleteSuccess" class="alert alert-success" role="alert">-->
    <!--        ユーザーが削除されました-->
    <!--      </div>-->
    <!--      <div v-if="deleteFailed" class="alert alert-danger" role="alert">-->
    <!--        This is a success alert—check it out!-->
    <!--      </div>-->
    <!--      <div class="row">-->
    <!--        <div class="col-2">-->
    <!--          <label for="adminType">管理者</label>-->
    <!--          <select id="adminType" v-model="adminType" :required="true"-->
    <!--                  class="form-control mt-1 filter-select form-select"-->
    <!--                  @change="getData">-->
    <!--            <option v-for="(adminTypeName, adminTypeId) in adminTypes" :value="adminTypeId">{{ adminTypeName }}</option>-->
    <!--          </select>-->
    <!--        </div>-->
    <!--        <div class="col-2">-->
    <!--          <label for="accountStatus">アカウント状態</label>-->
    <!--          <select id="accountStatus" v-model="accountStatus" class="form-control mt-1 filter-select form-select"-->
    <!--                  @change="getData">-->
    <!--            <option v-for="(accountStatusName, accountStatusId) in accountStatuses" :value="accountStatusId">-->
    <!--              {{ accountStatusName }}-->
    <!--            </option>-->
    <!--          </select>-->
    <!--        </div>-->
    <!--        <div class="col-4">-->
    <!--          <label for="keyword">キーワード</label>-->
    <!--          <div class="input-group mt-1">-->
    <!--            <input id="keyword" v-model="keyword" class="form-control py-2 bg-transparent filter-search-form"-->
    <!--                   placeholder="キーワードを入力" type="search">-->
    <!--            <span class="input-group-append">-->
    <!--                <button class="input-group-text bg-transparent p-2 border-left-radius-0" type="submit"-->
    <!--                        @click="search"><i-->
    <!--                    class="fa fa-search"></i></button>-->
    <!--            </span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="row mt-4">-->
    <!--        <div class="width-170">-->
    <!--          <button class="btn custom-button-outline fw-bold d-flex justify-content-around" @click="addBulkUser"><img-->
    <!--              src="../assets/images/ic_collective registration.png">一括新規登録-->
    <!--          </button>-->
    <!--        </div>-->
    <!--        <div class="width-170 float-right">-->
    <!--          <button class="btn custom-button fw-bold" @click="addUser"><i-->
    <!--              class="fas fa-plus float-start mt-1"></i>新規登録-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="datatable">-->
    <!--        <Datatable-->
    <!--            :columns="columns"-->
    <!--            :getData="getData"-->
    <!--            :loading="loading"-->
    <!--            :response="response"-->
    <!--            :sortable="true"-->
    <!--            @cta="cta">-->
    <!--        </Datatable>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 11-7 -->
    <!--    <div v-else-if="tournamentUserListScreen">-->
    <!--      <div class="container">-->
    <!--        <p class="breadcrumb-menu"><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i-->
    <!--            class="fas fa-angle-right form-control-color"></i> ユーザー情報</p>-->
    <!--        <div class="card border-0 text-first">-->
    <!--          <div class="row d-flex justify-content-between">-->
    <!--            <div class="col-6">-->
    <!--              <i class="fas fa-angle-left"></i><a @click="prevUser" class="text-decoration-underline cursor-pointer mx-1">-->
    <!--              {{  prevUserName }}{{ prevUserId === null ? '' : '('+prevUserId+')' }}</a>-->
    <!--            </div>-->
    <!--            <div class="col-6 text-end">-->
    <!--              <a @click="nextUser" class="text-decoration-underline cursor-pointer">{{  nextUserName }}{{ nextUserId === null ? '' : '('+nextUserId+')' }}</a><i-->
    <!--                class="fas fa-angle-right mx-1"></i>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="card-body px-5 mt-4 m-l-100 m-r-100">-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">入場管理番号</label>-->
    <!--                <input-->
    <!--                    v-model="user.userId"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    disabled-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="lastName">姓 名</label>-->
    <!--                <input id="lastName"-->
    <!--                    v-model="user.lastName"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--              <div class="col">-->
    <!--                <label for="firstName"></label>-->
    <!--                <input id="firstName"-->
    <!--                    v-model="user.firstName"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    placeholder="健介"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">メールアドレス</label>-->
    <!--                <input-->
    <!--                    v-model="user.email"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    type="email"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">生年月日</label>-->
    <!--                <input-->
    <!--                    v-model="user.birthday"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label>JTA管理者</label>-->
    <!--                <select class="form-control filter-select-long form-select mt-1">-->
    <!--                  <option :selected="user.isJTA" :value="true">JTA管理者</option>-->
    <!--                  <option :selected="!user.isJTA" :value="false">なし</option>-->
    <!--                </select>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">性別</label>-->
    <!--                <input-->
    <!--                    v-model="user.gender"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">国籍</label>-->
    <!--                <input-->
    <!--                    v-model="user.nationalityName"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">住所</label>-->
    <!--                <input-->
    <!--                    v-model="user.address"-->
    <!--                    class="form-control mt-2 full-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">電話番号</label>-->
    <!--                <input-->
    <!--                    v-model="user.phoneNo"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">新型コロナワクチン接種</label>-->
    <!--                <input-->
    <!--                    v-model="coronaVaccinationStatus"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1"></label>-->
    <!--                <input-->
    <!--                    v-model="user.latestCoronaVaccinationDate"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                    :disabled="coronaVaccinationStatus === 'なし'"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">PCR検査</label>-->
    <!--                <input-->
    <!--                    v-model="pcrTestResultStatus"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                />-->
    <!--              </div>-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1"></label>-->
    <!--                <input-->
    <!--                    v-model="user.latestPcrTestDate"-->
    <!--                    class="form-control mt-2 half-width-input"-->
    <!--                    type="text"-->
    <!--                    :disabled="pcrTestResultStatus === '未検査'"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="row mt-4">-->
    <!--              <div class="col form-check p-l-35">-->
    <!--                <input-->
    <!--                    id="flexCheckDefault"-->
    <!--                    v-model="user.pastAnswersRequired"-->
    <!--                    class="form-check-input"-->
    <!--                    type="checkbox"-->
    <!--                    value=""-->
    <!--                />-->
    <!--                <label class="form-check-label" for="flexCheckDefault">-->
    <!--                  過去14日間における回答をさせる-->
    <!--                </label>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--&lt;!&ndash;              TODO: Hide up to PH2&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="col-6">&ndash;&gt;-->
    <!--&lt;!&ndash;                <button class="btn disabled btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4 visually-hidden">&ndash;&gt;-->
    <!--&lt;!&ndash;                  パスワードリセット&ndash;&gt;-->
    <!--&lt;!&ndash;                </button>&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--&lt;!&ndash;              <div class="col-6">&ndash;&gt;-->
    <!--&lt;!&ndash;                <button @click="accountSuspend = !accountSuspend" v-if="accountSuspend" class="btn btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4">&ndash;&gt;-->
    <!--&lt;!&ndash;                  アカウント停止&ndash;&gt;-->
    <!--&lt;!&ndash;                </button>&ndash;&gt;-->
    <!--&lt;!&ndash;                <button v-else-if="!accountSuspend" @click="accountSuspend = !accountSuspend" class="btn btn-outline-primary height-44 w-100 font-weight-bold mx-auto mt-4">&ndash;&gt;-->
    <!--&lt;!&ndash;                  アカウント停止のキャンセル&ndash;&gt;-->
    <!--&lt;!&ndash;                </button>&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--            </div>-->
    <!--            <div class="row mt-5">-->
    <!--              <div class="col-5">-->
    <!--                <Modal :user-delete="deleteUser"-->
    <!--                       :back-to-root="backToUser"-->
    <!--                       :reload-data="getData"-->
    <!--                       confirmation-msg="ユーザーの削除を実行します。 よろしいですか？"-->
    <!--                       :data="user"-->
    <!--                       body=" に紐付いているすべての情報が削除され、この操作は後戻りできません。" button-title="削除" cancel-title="キャンセル"-->
    <!--                       ok-title="削除" title="削除"></Modal>-->
    <!--              </div>-->
    <!--              <div class="col-7 text-end">-->
    <!--                <button class="btn no" @click="backToUser">キャンセル</button>-->
    <!--                <button class="btn yes" @click="updateUser">変更</button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 11-6 -->
    <div v-if="batchRegistrationUserSuccessMsgScreen">
      <div class="card border-0">
        <div class="text-first">
          <div class="card-body mx-auto">
            <div class="d-flex flex-column text-center font-16">
              <div v-for="mes in uploadResponseMessage">
                <p>{{ mes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 11-5 -->
    <div v-else-if="batchRegistrationUserConfirmationScreen">
      <div class="">
        <div class="card border-0 text-first">
          <div class="card-body">
            <div class="d-flex justify-content-center">
              <p class="font-16">一括登録を実行します。よろしいですか？<br>処理の終了には数分かかる場合があります。</p>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col">
                <button :disabled="loading"
                        class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100 mx-auto mt-4"
                        @click="addBulkUser">
                  キャンセル
                </button>
              </div>
              <div class="col">
                <button :disabled="loading"
                        class="btn col btn-primary btn-outline-light font-weight-bold w-100 font-weight-bold mx-auto mt-4"
                        @click="submitFile">登録
                </button>
              </div>
            </div>
            <div class="mt-5 d-flex justify-content-center">
              <p v-if="loading">処理中です。処理の終了には数分かかる場合があります。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 11-3 -->
    <div v-else-if="addNewBulkUserScreen" class="card border-0">
      <div class="card-body">

        <div class="text-center">
          <div>
            <button class="btn btn-primary btn-download height-44"><img class="template-icon"
                                                                        src="../assets/images/document-text.png">
              <a class="text-decoration-none text-light" download href="/download/batch_template_users.xlsx">テンプレートファイルのダウンロード</a>
            </button>
          </div>
          <div class="input-group input-group-file mt-5 w-100 mx-auto">
            <input class="form-control selected-file" placeholder="ファイル選択" type="text"/>
            <span class="input-group-btn"><span class="btn btn-file"><img src="../assets/images/arrow-bar-up.svg">
                <input ref="file" accept=".xls, .xlsx" type="file" @change="fileSelected"/></span></span>
          </div>
          <div class="mt-5 row button-container btn-container mx-auto">
            <div class="col p-l-0">
              <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                      @click="backToUser">キャンセル
              </button>
            </div>
            <div class="col p-r-0">
              <button :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                      class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                      @click="batchRegistrationUserConfirmation">登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 11-1 -->

    <!-- 11-2 -->
    <!--    <div v-else-if="addUserScreen">-->
    <!--      <div class="container">-->
    <!--        <p><a class="text-decoration-underline cursor-pointer" @click="backToUser">ユーザー管理</a><i-->
    <!--            class="fas fa-angle-right form-control-color"></i> ユーザー新規登録</p>-->
    <!--        <div class="card p-5 text-first">-->
    <!--          <div class="card-body mx-auto">-->
    <!--            <div class="row">-->
    <!--              <div class="col-6">-->
    <!--                <label for="lastName2">姓 名</label>-->
    <!--                <input id="lastName2"-->
    <!--                    v-model="newUser.lastName"-->
    <!--                    class="form-control half-width-input mt-1"-->
    <!--                    placeholder=""-->
    <!--                    type="text"-->
    <!--                />-->
    <!--                <div class="form-error" v-show="errors['lastName']">{{ errors['lastName'] }}</div>-->
    <!--              </div>-->
    <!--              <div class="col-6">-->
    <!--                <label for="firstName2"></label>-->
    <!--                <input id="firstName2"-->
    <!--                    v-model="newUser.firstName"-->
    <!--                    class="form-control half-width-input mt-1"-->
    <!--                    placeholder=""-->
    <!--                    type="text"-->
    <!--                />-->
    <!--                <div class="form-error" v-show="errors['firstName']">{{ errors['firstName'] }}</div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label for="exampleInputPassword1">メールアドレス</label>-->
    <!--                <input-->
    <!--                    v-model="newUser.email"-->
    <!--                    class="form-control"-->
    <!--                    placeholder=""-->
    <!--                    type="email"-->
    <!--                />-->
    <!--              </div>-->
    <!--              <div class="form-error" v-show="errors['email']">{{ errors['email'] }}</div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4 dob-section">-->
    <!--              <label class="p-l-0">生年月日</label>-->
    <!--              <div class="d-inline-flex mt-1 p-l-0 p-r-0">-->
    <!--                <div class="input-group custom-select-year parent-block">-->
    <!--                  <div class="child-block">年</div>-->
    <!--                  <select-->
    <!--                      id="inputGroupSelectYear"-->
    <!--                      v-model="year"-->
    <!--                      class="form-control select_year"-->
    <!--                  >-->
    <!--                    <option v-for="year in years" :value="year">-->
    <!--                      {{ year }}-->
    <!--                    </option>-->
    <!--                  </select>-->
    <!--                </div>-->
    <!--                <div class="input-group custom-select-year parent-block">-->
    <!--                  <div class="child-block">月</div>-->
    <!--                  <select-->
    <!--                      id="inputGroupSelectMonth"-->
    <!--                      v-model="month"-->
    <!--                      class="form-control select_month"-->
    <!--                  >-->
    <!--                    <option selected value="01">01</option>-->
    <!--                    <option value="02">02</option>-->
    <!--                    <option value="03">03</option>-->
    <!--                    <option value="04">04</option>-->
    <!--                    <option value="05">05</option>-->
    <!--                    <option value="06">06</option>-->
    <!--                    <option value="07">07</option>-->
    <!--                    <option value="08">08</option>-->
    <!--                    <option value="09">09</option>-->
    <!--                    <option value="10">10</option>-->
    <!--                    <option value="11">11</option>-->
    <!--                    <option value="12">12</option>-->
    <!--                  </select>-->
    <!--                </div>-->
    <!--                <div class="input-group custom-select-year parent-block m-r-0">-->
    <!--                  <div class="child-block">日</div>-->
    <!--                  <select-->
    <!--                      id="inputGroupSelectDate"-->
    <!--                      v-model="date"-->
    <!--                      class="form-control select_date"-->
    <!--                  >-->
    <!--                    <option selected value="01">01</option>-->
    <!--                    <option value="02">02</option>-->
    <!--                    <option value="03">03</option>-->
    <!--                    <option value="04">04</option>-->
    <!--                    <option value="05">05</option>-->
    <!--                    <option value="06">06</option>-->
    <!--                    <option value="07">07</option>-->
    <!--                    <option value="08">08</option>-->
    <!--                    <option value="09">09</option>-->
    <!--                    <option value="10">10</option>-->
    <!--                    <option value="11">11</option>-->
    <!--                    <option value="12">12</option>-->
    <!--                    <option value="13">13</option>-->
    <!--                    <option value="14">14</option>-->
    <!--                    <option value="15">15</option>-->
    <!--                    <option value="16">16</option>-->
    <!--                    <option value="17">17</option>-->
    <!--                    <option value="18">18</option>-->
    <!--                    <option value="19">19</option>-->
    <!--                    <option value="20">20</option>-->
    <!--                    <option value="21">21</option>-->
    <!--                    <option value="22">22</option>-->
    <!--                    <option value="23">23</option>-->
    <!--                    <option value="24">24</option>-->
    <!--                    <option value="25">25</option>-->
    <!--                    <option value="26">26</option>-->
    <!--                    <option value="27">27</option>-->
    <!--                    <option value="28">28</option>-->
    <!--                    <option value="29">29</option>-->
    <!--                    <option value="30">30</option>-->
    <!--                    <option value="31">31</option>-->
    <!--                  </select>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="form-error" v-show="errors['birthday']">{{ errors['birthday'] }}</div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4">-->
    <!--              <div class="col">-->
    <!--                <label>JTA管理者</label>-->
    <!--                <select-->
    <!--                    v-model="newUser.isJTA" class="form-control filter-select-long form-select mt-1">-->
    <!--                  <option :value="true">JTA管理者</option>-->
    <!--                  <option :selected="true" :value="false">なし</option>-->
    <!--                </select>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="row mt-4 d-flex justify-content-between">-->
    <!--              <div class="col">-->
    <!--                <button class="btn cancel ml-2 font-weight-bold mx-auto mt-4" @click="backToUser">キャンセル-->
    <!--                </button>-->
    <!--              </div>-->
    <!--              <div class="col">-->
    <!--                <button class="btn ok font-weight-bold mx-auto mt-4" @click="createUser">登録-->
    <!--                </button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>


<script>
import {app, gender, datatable, apiConfig, defaults} from '@/constants'
import Datatable from '../components/datatable/Datatable'
import {mapGetters} from 'vuex'
import {AdminUploadResult} from "../assets/jsadminclient"
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";

export default {
  name: "UserBatchRegistration",
  components: {Datatable, Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      uploadResponseMessage: [],
      keyword: '',
      accountSuspend: true,
      response: {
        sort: 'userId',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      searchKeys: {
        userId: '',
        age: '',
        sex: '',
        country: '',
      },
      deleteSuccess: false,
      deleteFailed: false,
      gender: gender,
      loading: false,
      addNewBulkUserScreen: false,
      batchRegistrationUserConfirmationScreen: false,
      batchRegistrationUserSuccessMsgScreen: false,
      columns: datatable.columns.users,
      file: {},
      reloadKey: 1,
      userId: '',
      user: '',
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        birthday: "",
        isJTA: false
      },
      coronaVaccinationStatus: "",
      pcrTestResultStatus: "",
      year: "1990",
      month: "01",
      date: "01",
      errors: []
    }
  },
  mounted() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
    this.addBulkUser()
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    addBulkUser() {
      this.file = {}
      this.addNewBulkUserScreen = true
      this.batchRegistrationUserConfirmationScreen = false
    },
    backToUser() {
      this.addNewBulkUserScreen = false
      this.batchRegistrationUserConfirmationScreen = false
      this.batchRegistrationUserSuccessMsgScreen = false
      this.$router.push({
        name: 'Users',
        params: {defaultResponse: this.defaultResponse},
        query: this.createSearchQuery()
      })
    },
    batchRegistrationUserConfirmation() {
      this.batchRegistrationUserConfirmationScreen = true;
      this.batchRegistrationUserSuccessMsgScreen = false;
      this.addNewBulkUserScreen = false;
    },
    batchRegistrationUserSuccessMsg() {
      this.batchRegistrationUserSuccessMsgScreen = true;
      this.addNewBulkUserScreen = false;
      this.batchRegistrationUserConfirmationScreen = false;
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        this.loading = true
        const api = new AdminApi.JTAUserApi(this.getApiConfig());
        var result = await api.jtaUsersUploadPostAsync(
            this.file
        );
        if (result) {
          this.uploadResponseMessage.splice(0, this.uploadResponseMessage.length)

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.uploadResponseMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.uploadResponseMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.uploadResponseMessage.push(item)
          })

          this.batchRegistrationUserSuccessMsg();
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  }
}
</script>

<style lang="scss" scoped>

.breadcrumb-menu {
  margin-top: 0;
  padding-bottom: 22px;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ECE7E8;
}

.template-icon {
  margin: 0px 8px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #E0B1B7;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #F2ECF0;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  //background: #F2ECF0;
  border: none;
}

.form-control-admission-number {
  background: #FAF7F9;
  border: none;
  color: #888888;
}

.yes {
  //width: 225px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.no {
  //width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;
}

/*.input-group{*/
/*    width: 720px;*/
/*}*/

::placeholder {
  color: #333333;
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  //width: 160px;
}

.custom-button-outline.btn {
  background: #FFFFFF;
  color: #C95D6C;
  border: 1px solid #C95D6C;
  border-radius: 4px;
  //width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.ok {
  //width: 350px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.ok:active {
  color: white;
  background-color: #C95D6C;
}

.cancel {
  //width: 350px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;

  &:focus {
    //box-shadow: none !important;
  }
}

.cancel:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

.delete {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.ok-min:active {
  color: white;
  background-color: #C95D6C;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #C95D6C;
  border: none;
  background-color: #FFFFFF;
}

.cancel-min:active {
  color: #C95D6C;
  background-color: #FFFFFF;
}

a {
  color: #333333;
}

.btn-holo {
  border: 1px solid #C95D6C;
  opacity: 1;
}

.filter-select {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;

}

.filter-search-form {
  border: 1px solid #DEE2E6 !important;
  height: 32px !important;
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}


@media only screen and (min-width: 800px) {
  .card {
    &-body {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  .btn, .input-group {
    &-download {
      width: 320px;
    }

    &-file, &-container {
      max-width: 745px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .card {
    &-body {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.userbatchregistration {
  .btn {
    &-primary {
      &:disabled {
        pointer-events: none;
        opacity: 0.65;
        background-color: #C95D6C;
      }
    }
  }
}

</style>
